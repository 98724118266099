import React, {useEffect} from 'react'
import Nav from '../components/nav'
import Footer from '../components/footer'
import {gsap} from 'gsap'
import '../stylings/team.css'

// Assets
import {ReactComponent as BackgroundBase} from '../assets/background_colorful_v3.svg'
import {ReactComponent as BackgroundCutout} from '../assets/background_colorful_hand_v3.svg'
import {ReactComponent as Letters} from "../assets/team_letters.svg";
import {ReactComponent as DownArrow} from "../assets/down_arrow.svg";
import Vini from "../assets/Vini.JPG";
import Cornell from "../assets/Cornell.png";
import Tech from "../assets/Tech.png";
import Eeshan from "../assets/Eeshan_2.jpg";
import MIT from "../assets/MIT.png";
import {Helmet} from "react-helmet";



const Team= () => {
	useEffect(() => {
		let opening_home_animation = gsap.timeline({})
		opening_home_animation.from('.PageTitle', {display: "none", duration: 2, opacity: 0, delay: .5, ease: "slow(0.7, 0.7, false)"})
		opening_home_animation.from('.PageSubTitle', {display: "none", duration: 1, opacity: 0, ease: "slow(0.7, 0.7, false)"}, "-=1.5")
		opening_home_animation.from(['.NavButton', '.ArrowButton'], {display: "none", duration: 1, opacity: 0, y: () => Math.random()*200, stagger: .25, ease: "slow(0.7, 0.7, false)"}, "-=.5")

		// Get size length of text
		// const logo = document.querySelectorAll("#team_letters path");
		//
		// for (let i=0; i<logo.length; i++) {
		// 	console.log(i + ": " + logo[i].getTotalLength());
		// }
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Team</title>
				<meta name="description" content="Invictus BCI -- Meet the Founders"/>
			</Helmet>

			{/*Background Images*/}
			<div className={"BackgroundLayers"}>
				<BackgroundBase className={"PageBackgroundBase"}/>
				<div className={'TeamAccentLayer AccentLayer'}/>
				<BackgroundCutout className={"PageBackgroundCutout"}/>
			</div>

			{/*Title Page*/}
			<div className={'TeamGridLayer MainPageLayer MainGridLayer_2'}>
				<Nav/>
				<div className={'PageTitle'}>
					<Letters/>
				</div>
				<a className={'ArrowButton'} href="#section2">
					<DownArrow href="#section2" className={'MovingDownArrow'}/>
				</a>
				<div className={'TeamBodyPage'} id="section2">
					<div className={"ViniTeamSection"}>
						<div className={"ViniTeamImages"}>
							<img src={Vini} alt='Vini Tripathii' className={'ViniPhoto TeamPhoto'}/>
							<img src={Cornell} alt='Cornell University' className={'CornellTeamLogo TeamLogo'}/>
							<img src={Tech} alt='Cornell Tech' className={'TechTeamLogo TeamLogo'}/>
						</div>
						<div className={"ViniTeamTitle TeamTitle"}>Vini Tripathii</div>
						<div className={'ViniTeamText TeamText'}>
							<ul>
								<li>M.Eng (ECE) Cornell Tech 2020</li>
								<li>B.S. (ECE) Cornell Engineering 2019</li>
								<li>Selected as Top 100 Healthcare Leaders, 2020</li>
								<li>Conducted research in the Cornell Neuroengineering Lab. </li>
								<li>Recipient of ELI Grant for Research in Neuroengineering</li>
								<li>Recipient of Beck Fellowship for research in Neuroengineering</li>
								<li>Intel ISEF Grand Award recipient in 2016 </li>
								<li>Extensive background in digital signal processing, machine learning and BCI.</li>
							</ul>
						</div>
					</div>
					<div className={"EeshanTeamSection"}>
						<div className={"EeshanTeamImages"}>
							<img src={Eeshan} alt='Eeshan Tripathii' className={'EeshanPhoto TeamPhoto'}/>
							<img src={MIT} alt='Massachusetts Institute of Technology' className={'MITTeamLogo TeamLogo'}/>
						</div>
						<div className={"EeshanTeamTitle TeamTitle"}>Eeshan Tripathii</div>
						<div className={'EeshanTeamText TeamText'}>
							<ul>
								<li>B.S. Double Major (MechEng with concentration in CS; Management) MIT 2023</li>
								<li>Davidson Fellow 2018</li>
								<li>Intel ISEF Grand Award Recipient in 2018 </li>
								<li>Lead author of a paper published in the Journal of <a href="https://ascelibrary.org/doi/abs/10.1061/%28ASCE%29AE.1943-5568.0000300" target="_blank" rel="noopener noreferrer">Architectural Engineering</a>.</li>
								<li>Advances in Engineering identified this research  “as a key scientific article contributing to excellence in science and engineering research”</li>
								<li>Extensive experience in robotics, coding, IOT, microcontrollers, designing, CAD, 3D printing</li>
							</ul>
						</div>
					</div>
				</div>
				<div className={'PageSubTitle'}>
					Meet the Founders
				</div>
				<Footer/>
			</div>
		</>
	)
}

export default Team
